.Header {
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0.5rem 4rem;
}

.HeaderTitle {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 26px;
  color: var(--white);
}

.HeaderLink {
  font-family: "Roboto", sans-serif;
  color: var(--white);
  text-decoration: none; /* no underline */
}

.LogoImage {
  margin-right: 1rem;
}

.NavLink {
  margin-right: 1rem;
}
