.Button {
  width: 100%;
  background-color: #4f6bed;
  text-align: left;
  padding: 0.6em;
  color: var(--white);
  font-size: 14px;
  border: none;
  cursor: pointer;
}
