.LayoutMain {
  display: flex;
  /* min-height: calc(100vh - 4rem); */
  max-width: 100vw;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
}

.LayoutColLeft {
  padding: 2rem;
  background-color: var(--main-color);
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-height: calc(100vh - 4rem);
  min-height: calc(100vh - 4rem);
  justify-content: flex-end;
  overflow: auto;
}

.LayoutColRight {
  flex: 0 0 75%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  min-height: calc(100vh - 4rem);
}

.PageTitle {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  text-align: center;
  margin-top: 2rem;
}

.PageParagraph {
  text-align: center;
  margin-top: 1rem;
  font-family: "Roboto", sans-serif;
}

.BackButton {
  width: 100%;
  text-align: left;
  margin: 0;
  background-color: transparent;
  color: var(--white);
  text-decoration: none;
  border: none;
  border-top: 1px solid var(--sec-color);
  border-bottom: 1px solid var(--sec-color);
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  align-self: flex-start;
  justify-self: flex-end;
}

.LayoutFooter {
}
