.ImageContainer {
  display: block;
  margin: 0 auto;
  margin-top: 100px;
  max-width: 500px;
  /* min-height: 400px; */
  position: relative;
}

.Image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.ImageTitle {
  display: block;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: var(--white);
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
}
